import { action } from 'typesafe-actions';
import { COURIER_ACTION_TYPES } from './constants';
import { IGetCourierListReqModel } from './types';

export const fetchGetCourierList = (payload: IGetCourierListReqModel) =>
  action(COURIER_ACTION_TYPES.GET_COURIER_LIST, payload);
export const fetchSetCourierList = (payload: any) =>
  action(COURIER_ACTION_TYPES.SET_COURIER_LIST, payload);

export type AnyCourierAction =
  | ReturnType<typeof fetchGetCourierList>
  | ReturnType<typeof fetchSetCourierList>;
